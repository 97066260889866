<template>
  <div>
    <b-modal
      id="modal-input"
      size="lg"
      centered
      title="Tambah Data Master Mitra Pembantu"
      header-bg-variant="primary"
      header-text-variant="light"
    >
      <b-form>
        <b-form-group
          label="Kantor Pusat"
          label-cols-md="3"
          v-slot="{ ariaDescribedby }"
        >
          <b-form-radio
            v-model="data.is_pusat"
            :aria-describedby="ariaDescribedby"
            name="some-radios"
            value="1"
            >Ya</b-form-radio
          >
          <b-form-radio
            v-model="data.is_pusat"
            :aria-describedby="ariaDescribedby"
            name="some-radios"
            value="0"
            >Tidak</b-form-radio
          >
        </b-form-group>
        <b-form-group label="Nama Pusat" label-cols-md="3">
          <b-form-input
            :state="checkIfValid('namaPusat')"
            v-model="$v.data.namaPusat.$model"
            list="my-list-id"
          ></b-form-input>
          <datalist id="my-list-id">
            <option v-for="item in dataPusat" :key="item.namaPusat">
              {{ item.namaPusat }}
            </option>
          </datalist>
          <!-- <b-form-input
            type="text"
            :state="checkIfValid('namaPusat')"
            v-model="$v.data.namaPusat.$model"
          ></b-form-input> -->
        </b-form-group>
        <b-form-group label="Nama Cabang" label-cols-md="3">
          <b-form-input
            type="text"
            :state="checkIfValid('namaCabang')"
            v-model="$v.data.namaCabang.$model"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Kode Cabang" label-cols-md="3">
          <b-form-input
            type="text"
            :state="checkIfValid('kodeCabang')"
            v-model="$v.data.kodeCabang.$model"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Nama Mitra" label-cols-md="3">
          <multiselect
            v-model="$v.data.mitraId.$model"
            :state="checkIfValid('mitraId')"
            :options="dataMitra"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            label="namaMitra"
            placeholder="-- Pilih --"
          ></multiselect>
        </b-form-group>
        <b-form-group label="Provinsi" label-cols-md="3">
          <multiselect
            v-model="$v.data.provinsiId.$model"
            :state="checkIfValid('provinsiId')"
            :options="provinsi"
            @input="getKota(data.provinsiId)"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            label="namaProvinsi"
            track-by="id"
            placeholder="-- Pilih Provinsi --"
          ></multiselect>
        </b-form-group>

        <b-form-group label="Kota" label-cols-md="3">
          <multiselect
            v-model="$v.data.kotaId.$model"
            :state="checkIfValid('kotaId')"
            :options="kota"
            @input="getKecamatan(data.kotaId)"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            label="namaKota"
            track-by="idKota"
            placeholder="-- Pilih Kabupaten/Kota --"
          ></multiselect>
        </b-form-group>

        <b-form-group label="Kecamatan" label-cols-md="3">
          <multiselect
            v-model="$v.data.kecamatanId.$model"
            :state="checkIfValid('kecamatanId')"
            :options="kec"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            label="namaKecamatan"
            track-by="idKecamatan"
            placeholder="-- Pilih Kecamatan --"
          ></multiselect>
        </b-form-group>
        <b-form-group label="Alamat" label-cols-md="3">
          <b-form-input
            type="text"
            :state="checkIfValid('alamatCabang')"
            v-model="$v.data.alamatCabang.$model"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="No. Telp" label-cols-md="3">
          <b-form-input
            type="number"
            :state="checkIfValid('noHpKantor')"
            v-model="$v.data.noHpKantor.$model"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Latitude" label-cols-md="3">
          <b-form-input
            type="text"
            :state="checkIfValid('latitude')"
            v-model="$v.data.latitude.$model"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Longtitude" label-cols-md="3">
          <b-form-input
            type="text"
            :state="checkIfValid('longitude')"
            v-model="$v.data.longitude.$model"
          ></b-form-input>
        </b-form-group>
      </b-form>
      <!-- <img :src="src1" /> -->
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-input')">
          Tutup
        </b-button>
        <b-button
          variant="primary"
          :disabled="busy || !isValid"
          @click="simpan()"
        >
          {{ button }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import _ from "lodash";
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
export default {
  components: { Multiselect },
  props: ["dataMitra", "provinsi", "dataPusat"],
  data() {
    return {
      data: {
        is_pusat: "",
        namaPusat: "",
        namaCabang: "",
        kodeCabang: "",
        mitraId: "",
        provinsiId: "",
        kotaId: "",
        kecamatanId: "",
        alamatCabang: "",
        noHpKantor: "",
        longitude: 0,
        latitude: 0,
      },
      kota: [],
      kec: [],
      busy: false,
      button: "Simpan",
    };
  },
  computed: {
    formString() {
      return JSON.stringify(this.data, null, 4);
    },
    isValid() {
      return !this.$v.data.$invalid;
    },
    isDirty() {
      return this.$v.data.$anyDirty;
    },
  },
  mixins: [validationMixin],
  validations: {
    data: {
      mitraId: {
        required,
      },
      provinsiId: {
        required,
      },
      kotaId: {
        required,
      },
      kecamatanId: {
        required,
      },
      namaPusat: {
        required,
      },
      namaCabang: {
        required,
      },
      kodeCabang: {
        required,
      },
      alamatCabang: {
        required,
      },
      noHpKantor: {
        required,
      },
      longitude: {
        required,
      },
      latitude: {
        required,
      },
    },
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.data[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    async getKota(x) {
      let kota = await axios.get(ipBackend + "kota/listByProvinsiId/" + x.id);
      // console.log(kota.data.data);
      this.kota = kota.data.data;
    },
    async getKecamatan(x) {
      let kec = await axios.get(
        ipBackend + "kecamatan/listByKotaId/" + x.idKota
      );
      // console.log(kec.data.data);
      this.kec = kec.data.data;
    },
    simpan() {
      let vm = this;
      vm.data.provinsiId = vm.data.provinsiId.id;
      vm.data.mitraId = vm.data.mitraId.id;
      vm.data.kotaId = vm.data.kotaId.idKota;
      vm.data.kecamatanId = vm.data.kecamatanId.idKecamatan;
      vm.data.is_pusat = parseInt(vm.data.is_pusat);
      axios
        .post(ipBackend + "cabang/register", vm.data)
        .then((res) => {
          if (res.data.message == "sukses") {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "success",
              msg: "BERHASIL MENDAFTARKAN CABANG",
              showing: true,
            });
            this.data.namaPusat = "";
            this.data.namaCabang = "";
            this.data.kodeCabang = "";
            this.data.alamatCabang = "";
            this.data.noHpKantor = "";
            this.data.is_pusat = "";
            this.$bvModal.hide("modal-input");
          } else {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: _.toUpper(res.data.message),
              showing: true,
            });
          }
        })
        .catch((err) => {
          vm.button = "Simpan";
          vm.busy = false;
          vm.$emit("alertFromChild", {
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
            showing: true,
          });
        });
    },
  },
};
</script>
